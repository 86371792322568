import InfoIcon from "@mui/icons-material/Info";
import { Box, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { KTCard } from "../../_metronic/helpers";
import { PageTitle } from "../../_metronic/layout/core";
import { getSubscriptionInfo } from "../../api";
import SubscriptionInfoDetails from "../../components/subscription-info/SubscriptionInfoDetails";
import MuiTable from "../../components/table/MuiTable";
import { formatDate } from "../../helpers/commonFunctions";
import useDebounce from "../../hooks/useDebounce";

const SubscriptionInfo = () => {
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
  });
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 800);

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const tableRef = useRef();

  const {
    mutateAsync: getSubscriptionInfoData,
    data: subscriptionData,
    isLoading: isLoadingData,
  } = useMutation("data-list", getSubscriptionInfo);

  useEffect(() => {
    getSubs(tableOptions);
  }, []);

  useEffect(() => {
    getSubs({
      ...tableOptions,
      search: debounceSearchValue ?? "",
    });
  }, [debounceSearchValue]);

  const getSubs = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined,
  ) => {
    let response = await getSubscriptionInfoData(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setSelectedRow("");
    if (isSubmit) {
      let query = {
        offset: tableOptions?.offset ?? 0,
        limit: tableOptions?.limit ?? 10,
        search: debounceSearchValue ?? "",
      };
      getSubs(query);
    }
  };

  const columns: any = [
    {
      field: "title",
      headerName: "Subscription",
      sortable: false,
      flex: 1,
      renderCell: (data: any) =>
        data?.row?.subscriptionId ? data?.row?.subscriptionId?.title : "N/A",
    },
    {
      field: "subscriptionId",
      headerName: "Duration (in months)",
      sortable: false,
      flex: 1,
      renderCell: (data: any) =>
        data?.row?.subscriptionId ? data?.row?.subscriptionId?.duration : "N/A",
    },
    {
      field: "createdAt",
      headerName: "Created At (MM/DD/YY)",
      sortable: false,
      flex: 1,
      renderCell: (data: any) =>
        data?.row?.createdAt
          ? formatDate(data?.row?.createdAt, "MM/dd/yy")
          : "N/A",
    },
    {
      field: "expireDate",
      headerName: "Expire Date (MM/DD/YY)",
      sortable: false,
      flex: 1,
      renderCell: (data: any) =>
        data?.row?.expireDate
          ? formatDate(data?.row?.expireDate, "MM/dd/yy")
          : "N/A",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (data: any) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Info">
            <InfoIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowDetailsPopup(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? "",
    };
    getSubs(query);
  };

  return (
    <>
      <Card className="p-6">
        <KTCard>
          <MuiTable
            handleAddClick={() => setShowAddEditModal(true)}
            columns={columns}
            data={subscriptionData?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isLoadingData}
            isRowClickable={true}
            tableOptions={tableOptions}
            isAddEnable={false}
            handlePagination={handlePagination}
            // filters={
            //   <Box pb={3} display="flex" alignItems="center">
            //     <Box>
            //       <label
            //         className="form-label fw-bold"
            //         style={{ marginLeft: 5 }}
            //       >
            //         Search By Title
            //       </label>
            //       <input
            //         type="text"
            //         className="form-control form-control-lg form-control-solid"
            //         placeholder="Search"
            //         aria-label="Search"
            //         onChange={(e: any) =>
            //           handleChangeFilters("search", e?.target?.value)
            //         }
            //         value={tableOptions?.search}
            //       />
            //     </Box>
            //   </Box>
            // }
          />
        </KTCard>
        {showDetailsPopup && (
          <SubscriptionInfoDetails
            details={selectedRow}
            show={showDetailsPopup}
            handleClose={() => setShowDetailsPopup(false)}
          />
        )}
      </Card>
    </>
  );
};

export default SubscriptionInfo;
