import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { KTCard } from "../../_metronic/helpers";
import ConfirmEventPopup from "../../_metronic/layout/components/common/ConfirmEventPopup";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import { PageTitle } from "../../_metronic/layout/core";
import { deleteCoupon, getAllCoupons } from "../../api";
import AddEditCoupon from "../../components/coupon/AddEditCoupon";
import MuiTable from "../../components/table/MuiTable";
import { charValidate } from "../../components/utility";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { formatCurrency, formatDate } from "../../helpers/commonFunctions";
import useDebounce from "../../hooks/useDebounce";

const Coupon = () => {
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
  });
  let defaultQuery = {
    offset: 0,
    limit: 10,
    search: "",
  };
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 800);

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const tableRef = useRef();

  const {
    mutateAsync: getCouponsData,
    data: dataList,
    isLoading: isLoadingData,
  } = useMutation("data-list", getAllCoupons);
  const { mutateAsync: deleteListItem, isLoading: isDeleting } = useMutation(
    "delete",
    deleteCoupon,
  );

  useEffect(() => {
    getCoupon(tableOptions);
  }, []);

  useEffect(() => {
    getCoupon({
      ...tableOptions,
      search: debounceSearchValue ?? "",
    });
  }, [debounceSearchValue]);

  const getCoupon = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined,
  ) => {
    let response = await getCouponsData(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setSelectedRow("");
    if (isSubmit) {
      let query = {
        offset: tableOptions?.offset ?? 0,
        limit: tableOptions?.limit ?? 10,
        search: debounceSearchValue ?? "",
      };
      getCoupon(query);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: "Code",
      flex: 1,
      sortable: false,
      renderCell: (data) => {
        return (
          <CustomTooltip
            title={
              data?.row?.code ? (
                <h6 style={{ color: "white" }}>{data?.row?.code ?? "N/A"}</h6>
              ) : (
                "N/A"
              )
            }
          >
            <span>
              {data?.row?.code
                ? charValidate(data?.row?.code ?? "", 25)
                : "N/A"}
            </span>
          </CustomTooltip>
        );
      },
    },
    {
      field: "startDate",
      headerName: "Start Date (MM/DD/YY)",
      flex: 1,
      sortable: false,
      renderCell: (data) =>
        data?.row?.startDate
          ? formatDate(data?.row?.startDate, "MM/dd/yy")
          : "N/A",
    },
    {
      field: "endDate",
      headerName: "End Date (MM/DD/YY)",
      flex: 1,
      sortable: false,
      renderCell: (data) =>
        data?.row?.endDate ? formatDate(data?.row?.endDate, "MM/dd/yy") : "N/A",
    },
    {
      field: "flatOff",
      headerName: "Flat-Off ($ or %)",
      flex: 1,
      sortable: false,
      renderCell: (data) =>
        data?.row?.flatOff || data?.row?.flatOffPer
          ? data?.row?.flatOff
            ? formatCurrency(data?.row?.flatOff)
            : `%${data?.row?.flatOffPer}`
          : "N/A",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Edit">
            <DriveFileRenameOutlineIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowAddEditModal(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteSweepIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowDeleteConfirmModal(true);
              }}
              sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
              color="error"
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? "",
    };
    getCoupon(query);
  };

  const confirmDelete = async () => {
    let response = await deleteListItem({ id: selectedRow?._id });
    if (response?.status) {
      snackActions.success(response?.message);
      handleCloseDelete();
      let query = {
        ...defaultQuery,
        offset: tableOptions?.offset ?? 0,
        limit: tableOptions?.limit ?? 10,
      };
      getCoupon(query);
    } else {
      snackActions.error(response?.message);
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setSelectedRow("");
  };

  return (
    <>
      <Card className="p-6">
        <KTCard>
          <MuiTable
            handleAddClick={() => setShowAddEditModal(true)}
            addLabel="Add Coupon"
            columns={columns}
            data={dataList?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isLoadingData}
            isRowClickable={true}
            tableOptions={tableOptions}
            handlePagination={handlePagination}
            // filters={
            //   <Box pb={3} display="flex" alignItems="center">
            //     <Box>
            //       <label
            //         className="form-label fw-bold"
            //         style={{ marginLeft: 5 }}
            //       >
            //         Search By Title
            //       </label>
            //       <input
            //         type="text"
            //         className="form-control form-control-lg form-control-solid"
            //         placeholder="Search"
            //         aria-label="Search"
            //         onChange={(e: any) =>
            //           handleChangeFilters("search", e?.target?.value)
            //         }
            //         value={tableOptions?.search}
            //       />
            //     </Box>
            //   </Box>
            // }
          />
        </KTCard>
        {showAddEditModal && (
          <AddEditCoupon
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
          />
        )}
        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={confirmDelete}
            loading={isDeleting}
            title={"Delete Coupon"}
            message={"Are you sure, want to delete this coupon?"}
          />
        )}
      </Card>
    </>
  );
};

export default Coupon;
