export const categoryDataForGolf = [
    {
        catKey: 'Skill',
        title: 'Skill',
        description: 'Incorporate skills into your areas of interest.'
    },
    {
        catKey: 'Frequency',
        title: 'Frequency',
        description: 'Incorporate Frequency into your areas of interest.'
    },
    {
        catKey: 'TimeRange',
        title: 'Time Range',
        description: 'Incorporate Time Range into your areas of interest.'
    },
    {
        catKey: 'Purpose',
        title: 'Primary Purpose',
        description: 'Incorporate Purpose into your areas of interest.'
    },
    {
        catKey: 'Tolerance',
        title: 'Preferences',
        description: 'Incorporate Tolerance into your areas of interest.'
    },
    {
        catKey: 'Course',
        title: 'Typical Courses',
        description: 'Incorporate Course into your areas of interest.'
    },
    {
        catKey: 'Payment',
        title: 'Payment Style',
        description: 'Incorporate Payment into your areas of interest.'
    },
    {
        catKey: 'Boxes',
        title: 'Dislikes',
        description: 'Incorporate Boxes into your areas of interest.'
    },
    {
        catKey: 'Music',
        title: 'Music',
        description: 'Incorporate Music into your areas of interest.'
    },
    {
        catKey: 'Industry',
        title: 'Industry',
        description: 'Incorporate Industry into your areas of interest.'
    },
    {
        catKey: 'GolfClubBrand',
        title: 'Golf Club Brand',
        description: 'Incorporate Golf Club Brand into your areas of interest.'
    },
    {
        catKey: 'GolfClubType',
        title: 'Golf Club Type',
        description: 'Incorporate Golf Club Type into your areas of interest.'
    },
    {
        catKey: 'GolfCourse',
        title: 'Golf Course',
        description: 'Incorporate Golf Course into your areas of interest.'
    },
    {
        catKey: 'Polo',
        title: 'Polo',
        description: 'Incorporate Polo into your areas of interest.'
    },
    {
        catKey: 'Hats',
        title: 'Hats',
        description: 'Incorporate Hats into your areas of interest.'
    },
    {
        catKey: 'Shoes',
        title: 'Shoes',
        description: 'Incorporate Shoes into your areas of interest.'
    },
    {
        catKey: 'Shorts',
        title: 'Shorts',
        description: 'Incorporate Shorts into your areas of interest.'
    },
    {
        catKey: 'Cloth',
        title: 'Cloth',
        description: 'Incorporate Cloth into your areas of interest.'
    },
    // Add more categories as needed
];