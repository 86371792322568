import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import PhoneInput from "react-phone-input-2";
import { useMutation, useQuery } from "react-query";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import {
  addCategory,
} from "../../api";
import {IRestaurant } from "../../constants/types";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: IRestaurant;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditCategory= ({ show, handleClose, editData }: Props) => {
  
  const profileDetailsSchema = Yup.object().shape({
    title: Yup.string().trim().required("Title is required"),
    description: Yup.string().required("Description is required"),
  });


  const {
    mutateAsync: addCategoryData,
    isLoading: isAddingCategory,
    error: addGoldAPIError,
  } = useMutation("add-Category", addCategory);

  const formik: any = useFormik({
    initialValues: {
      title: editData?.name ? editData?.name?.replace(/^\s+|\s+$/g, "") : "",
      description: editData?.description ?? "",
      
    },
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      let bodyParams = {
        _id: editData ? editData?._id : undefined,
        name: values?.title ? values?.title?.replace(/^\s+|\s+$/g, "") : "",
        description: values?.description ?? "",
      };

      let response = await addCategoryData(bodyParams);
      if (response?.status) {
        snackActions.success(response?.message ?? "Restaurant Added Successfully!");
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px scroll"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>Add / Edit Category</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}>
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div>
          <div className="mb-8">
          <label className="form-label fw-bold">Title</label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                placeholder="Title"
                aria-label="title"
                {...formik.getFieldProps("title")}
              />
              {formik.touched.title && formik.errors.title && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.title}</div>
                </div>
              )}
            </div>
            <div className="mb-8">
              <label className="form-label fw-bold">Description</label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                placeholder="Description"
                aria-label="description"
                {...formik.getFieldProps("description")}
              />
              {formik.touched.description && formik.errors.description && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.description}</div>
                </div>
              )}
            </div>
          </div>
          <div className="w-100 d-flex p-5 align-items-center">
            <Button
              onClick={() => {
                formik.resetForm();
                //setImgFileName("");
                //setGolfImg("");
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              {isAddingCategory ? "Loading..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>

      {/* <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>

          </Container>
         
        </form>
      </div> */}
    </Modal>,
    modalsRoot,
  );
};


export default AddEditCategory;