import { Box, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ReactChild, ReactComponentElement, ReactElement } from "react";
import { Button } from "react-bootstrap";
import { KTIcon } from "../../_metronic/helpers";

export default function MuiTable({
  isAddEnable = true,
  isBackEnable = false,
  isRowClickable = false,
  hideFooterPagination = false,
  addLabel = "",
  handleAddClick,
  handleBackClick,
  columns,
  data,
  tableRef,
  options,
  loading = false,
  children,
  handleCellClick,
  actionButtons,
  handlePagination,
  tableOptions,
  filters = null,
  showComingSoon = false, // New prop to conditionally show "Coming Soon"
}: {
  isAddEnable?: boolean;
  isBackEnable?: boolean;
  isRowClickable?: boolean;
  hideFooterPagination?: boolean;
  addLabel?: string;
  handleAddClick?: () => void;
  handleBackClick?: () => void;
  handleCellClick?: (data: any) => any;
  columns?: GridColDef[];
  data?: any;
  tableRef?: any;
  options?: any;
  loading?: boolean;
  children?: ReactChild;
  actionButtons?: ReactComponentElement<any>;
  handlePagination?: (values: { page: number; pageSize: number }) => void;
  tableOptions?: any;
  filters?: ReactElement | null;
  showComingSoon?: boolean; // New prop
}) {
  return (
    <div className="w-100 d-flex flex-column">
      <div className="w-100 d-flex align-self-end align-items-center">
        {actionButtons}
        <div className={isBackEnable ? "back-button-class d-flex mb-5 w-100" : "table-add-btn"}>
          {isBackEnable && (
            <Button onClick={handleBackClick} className="d-flex align-items-center btn btn-secondary me-auto">
              <KTIcon iconName="arrow-left" className="fs-1" />
              Back
            </Button>
          )}
          {!showComingSoon && isAddEnable && (
            <Button onClick={handleAddClick} className="d-flex align-items-center">
              <KTIcon iconName="plus" className="fs-1" />
              {addLabel}
            </Button>
          )}
        </div>
      </div>
      {!showComingSoon && (
        <div>{children}</div>
      )}
      <Box
        sx={{
          width: "100%",
          flex: 1,
          display: showComingSoon ? 'flex' : 'block',
          alignItems: showComingSoon ? 'center' : 'flex-start',
          justifyContent: showComingSoon ? 'center' : 'flex-start',
        }}
      >
        {!showComingSoon && filters}
        {showComingSoon ? (
          <Box
            sx={{
              width: "100%",
              height: "50vh",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h4" component="div">
              Coming Soon
            </Typography>
          </Box>
        ) : (
          <DataGrid
            rows={data ?? []}
            columns={columns ?? []}
            pageSizeOptions={[10, 25, 50, 100]}
            ref={tableRef ?? null}
            pagination={true}
            getRowId={(data) => data?._id ?? data?.holeNumber}
            paginationMode="server"
            loading={loading}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: tableOptions?.limit,
                  page: tableOptions?.offset,
                },
              },
            }}
            disableColumnFilter={true}
            hideFooterPagination={hideFooterPagination}
            disableColumnMenu={true}
            disableColumnSelector={true}
            onCellClick={handleCellClick}
            onPaginationModelChange={handlePagination}
            rowCount={tableOptions?.totalRowCount}
            slots={{
              loadingOverlay: () => (
                <Typography
                  variant="h4"
                  fontWeight={500}
                  fontSize={13}
                  sx={noDataLoadingLabelStyle}
                >
                  Loading...
                </Typography>
              ),
              noRowsOverlay: () => (
                <Typography
                  variant="h4"
                  fontWeight={500}
                  fontSize={13}
                  sx={noDataLoadingLabelStyle}
                >
                  No Data Found
                </Typography>
              ),
            }}
            sx={{
              width: 1,
              "& .MuiDataGrid-overlayWrapper": {
                minHeight: "150px !important",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                textTransform: "uppercase !important",
                fontWeight: "400 !important",
              },
              ".MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-row:hover": {
                cursor: isRowClickable ? "pointer" : "initial",
              },
            }}
            {...options}
          />
        )}
      </Box>
    </div>
  );
}

const noDataLoadingLabelStyle = {
  textAlign: "center",
  position: "relative",
  top: "30%",
};
