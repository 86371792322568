import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import { Box, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { KTCard, KTIcon } from "../../_metronic/helpers";
import ConfirmEventPopup from "../../_metronic/layout/components/common/ConfirmEventPopup";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import {
  activeDeActiveGolfClub,
  deleteGolfClub,
  getAllGolfClub,
} from "../../api";
import MuiTable from "../../components/table/MuiTable";
import { charValidate } from "../../components/utility";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import useDebounce from "../../hooks/useDebounce";
import { isSuperAdmin } from "../../hooks/useUserInfo";
import AddEditGolf from "./AddEditGolf";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slice/header/headerSlice";

const GolfClub: React.FC = () => {
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showActiveDeActiveGolfPopup, setShowActiveDeActiveGolfPopup] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    type: "",
    golfType: "",
    search: "",
  });
  const tableRef = useRef();
  const navigate = useNavigate();
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

  const {
    mutateAsync: getGolfData,
    data: golfData,
    isLoading: isGettingGolfData,
  } = useMutation("golf-list", getAllGolfClub);

  const { mutateAsync: deleteGolfData, isLoading: isDeletingGolf } =
    useMutation("delete-golf", deleteGolfClub);
  const { mutateAsync: activeDeActiveGolf, isLoading: isActiveDeActiveGolf } =
    useMutation("active-golf", activeDeActiveGolfClub);

  useEffect(() => {
    getGolf(tableOptions);
  }, []);

  useEffect(() => {
    getGolf({
      ...tableOptions,
      search: debounceSearchValue ? debounceSearchValue : "",
    });
  }, [tableOptions?.type, tableOptions?.golfType, debounceSearchValue]);

  const getGolf = async (
    query:
      | {
          offset: number | string;
          limit: number;
          type: string;
          golfType: string;
          search: string;
        }
      | undefined,
  ) => {
    let response = await getGolfData(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.3,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row?.name ? (
              <h6 style={{ color: "white" }}>{data?.row?.name}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.name ? charValidate(data?.row?.name, 30) : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "golfType",
      headerName: "Golf Type",
      flex: 0.8,
      sortable: false,
      renderCell: (data: any) => data?.row?.golfType ?? "N/A",
    },
    {
      field: "type",
      headerName: "Holes Type",
      flex: 0.7,
      sortable: false,
      renderCell: (data: any) => data?.row?.type ?? "N/A",
    },
    {
      field: "isActive",
      headerName: "Status",
      flex: 0.7,
      sortable: false,
      renderCell: (data: any) =>
        typeof data?.row?.isActive !== "undefined" ? (
          data?.row?.isActive ? (
            <div className="d-flex align-items-center">
              <CheckCircleIcon sx={{ fontSize: 15, mr: 1, color: "green" }} />
              <span>Active</span>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <CheckCircleIcon sx={{ fontSize: 15, mr: 1 }} color="disabled" />
              <span>Inactive</span>
            </div>
          )
        ) : (
          "N/A"
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      // flex: 1,
      sortable: false,
      headerAlign: "right",
      align: "right",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          {isSuperAdmin && (
            <Tooltip title="Make Golf Active/De-Active">
              <GolfCourseIcon
                onClick={() => {
                  setSelectedRow(data.row);
                  setShowActiveDeActiveGolfPopup(true);
                }}
                sx={{ fontSize: 22, mr: 1.2, cursor: "pointer" }}
                color="primary"
              />
            </Tooltip>
          )}
          <Tooltip title="Edit">
            <DriveFileRenameOutlineIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowAddEditModal(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
          </Tooltip>
          {isSuperAdmin && (
            <Tooltip title="Delete">
              <DeleteSweepIcon
                onClick={() => {
                  setSelectedRow(data.row);
                  setShowDeleteConfirmModal(true);
                }}
                sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
                color="error"
              />
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setSelectedRow("");
    if (isSubmit) {
      let query = {
        offset: tableOptions?.offset ?? 0,
        limit: tableOptions?.limit ?? 10,
        type: tableOptions?.type ?? "",
        golfType: tableOptions?.golfType ?? "",
        search: debounceSearchValue ?? "",
      };
      getGolf(query);
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setSelectedRow("");
  };

  const handleCloseActiveGolfPopup = () => {
    setShowActiveDeActiveGolfPopup(false);
    setSelectedRow("");
  };

  const deleteGolf = async () => {
    let response = await deleteGolfData({ id: selectedRow?._id });
    if (response?.status) {
      snackActions.success(response?.message);
      handleCloseDelete();
      let query = {
        offset: tableOptions?.offset ?? 0,
        limit: tableOptions?.limit ?? 10,
        type: tableOptions?.type ?? "",
        golfType: tableOptions?.golfType ?? "",
        search: debounceSearchValue ?? "",
      };
      getGolf(query);
    } else {
      snackActions.error(response?.message);
    }
  };

  const handleCellClick = (data: any) => {
    let golfData: any = data?.row;
    let fieldName: string = data?.field;
    if (fieldName !== "actions") {
      navigate("/golf-holes", { state: golfData });
    }
  };

  const activeDeactiveGolf = async () => {
    let response = await activeDeActiveGolf({
      golfId: selectedRow?._id,
      active: !selectedRow?.isActive,
    });
    if (response?.status) {
      snackActions.success(response?.message);
      handleCloseActiveGolfPopup();
      let query = {
        offset: tableOptions?.offset ?? 0,
        limit: tableOptions?.limit ?? 10,
        type: tableOptions?.type ?? "",
        golfType: tableOptions?.golfType ?? "",
        search: debounceSearchValue ?? "",
      };
      getGolf(query);
    } else {
      snackActions.error(response?.message);
    }
  };

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      type: tableOptions?.type ?? "",
      golfType: tableOptions?.golfType ?? "",
      search: debounceSearchValue ?? "",
    };
    getGolf(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const dispatch = useDispatch();

  const handleBackClick = () => {
    navigate("/category");
    dispatch(setData("Category"));
  };

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <MuiTable
            columns={columns}
            isAddEnable={false}
            data={golfData?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isGettingGolfData}
            isBackEnable={true}
            handleBackClick={handleBackClick}
            isRowClickable={true}
            handleCellClick={handleCellClick}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <Grid container spacing={2} pb={3}>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <Box>
                    <label
                      className="form-label fw-bold"
                      style={{ marginLeft: 5 }}
                    >
                      Holes Type
                    </label>
                    <select
                      onChange={(e: any) =>
                        handleChangeFilters("type", e?.target?.value)
                      }
                      value={tableOptions?.type}
                      className="form-select form-select-solid form-select-lg"
                    >
                      <option value="">Select All</option>
                      <option value="9Holes">9 Holes</option>
                      <option value="18Holes">18 Holes</option>
                    </select>
                  </Box>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <Box>
                    <label
                      className="form-label fw-bold"
                      style={{ marginLeft: 5 }}
                    >
                      Golf Type
                    </label>
                    <select
                      onChange={(e: any) =>
                        handleChangeFilters("golfType", e?.target?.value)
                      }
                      value={tableOptions?.golfType}
                      className="form-select form-select-solid form-select-lg"
                    >
                      <option value="">Select All</option>
                      <option value="Public">Public</option>
                      <option value="Private">Private</option>
                      <option value="SemiPrivate">SemiPrivate</option>
                      <option value="Resort">Resort</option>
                    </select>
                  </Box>
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                  <Box>
                    <label
                      className="form-label fw-bold"
                      style={{ marginLeft: 5 }}
                    >
                      Search By Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Search by name"
                      aria-label="Search"
                      style={{
                        backgroundColor: "#E0DEE7",
                        border: "0px solid #ccc",
                        borderRadius: "8px",
                        outline: "none",
                      }}
                      onChange={(e: any) =>
                        handleChangeFilters("search", e?.target?.value)
                      }
                      value={tableOptions?.search}
                    />
                  </Box>
                </Grid>
                {isSuperAdmin && (
                  <div className="ms-auto" style={{ marginTop: 43 }}>
                    <Button
                      className="d-flex align-items-center"
                      onClick={() => setShowAddEditModal(true)}
                    >
                      <KTIcon iconName="plus" className="fs-1" />
                      Add Golf
                    </Button>
                  </div>
                )}
              </Grid>
            }
          />
        </KTCard>
        {showAddEditModal && (
          <AddEditGolf
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
          />
        )}
        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={deleteGolf}
            loading={isDeletingGolf}
            title={"Delete Golf"}
            message={"Are you sure, want to delete this golf?"}
          />
        )}
        {showActiveDeActiveGolfPopup && (
          <ConfirmEventPopup
            showPopup={showActiveDeActiveGolfPopup}
            handleClose={handleCloseActiveGolfPopup}
            handleConfirmed={activeDeactiveGolf}
            loading={isActiveDeActiveGolf}
            title={"Active / Deactive Golf"}
            message={`Are you sure, want to ${
              selectedRow?.isActive ? '"Deactivate"' : '"Activate"'
            } this golf club?`}
          />
        )}
      </>
    </Card>
  );
};

export default GolfClub;
