import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { MultiSelect } from "react-multi-select-component";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import AmountInput from "../../_metronic/layout/components/common/AmountInput";
import { addEditCoupon, getAllGolfClub } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: {
    _id?: string;
    golfs: string[];
    code: string;
    startDate: string;
    endDate: string;
    flatOff: number;
    flatOffPer: number;
  };
};

interface ISelectedGolf {
  label: string;
  value: string;
}

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditCoupon = ({ show, handleClose, editData }: Props) => {
  const [selectedGolfs, setSelectedGolf] = useState<ISelectedGolf[] | []>([]);
  const { mutateAsync: addingEditingCoupon, isLoading: isAddingEditing } =
    useMutation("add-edit", addEditCoupon);
  const {
    mutateAsync: getGolfClubs,
    data: golfData,
    isLoading: isGettingGolfData,
  } = useMutation("golf-list", getAllGolfClub);

  const validationSchema = Yup.object().shape({
    startDate: Yup.string().required("Start date is required"),
    endDate: Yup.string().required("End date is required"),
    flatOff: Yup.number().required("Flat-Off is required"),
    code: Yup.string().required("Coupon code is required"),
  });

  useEffect(() => {
    getGolfClubs({ limit: 100000 });
  }, []);

  useEffect(() => {
    if (editData && golfData) {
      let golfList = golfData?.data?.docs;
      if (golfList?.length) {
        let selectedGolfList: any = [];
        golfList?.forEach((golf: any) => {
          if (editData?.golfs?.includes(golf?._id)) {
            selectedGolfList?.push({ label: golf?.name, value: golf?._id });
          }
        });
        setSelectedGolf(selectedGolfList);
      }
    }
  }, [editData, golfData]);

  const formik = useFormik({
    initialValues: {
      startDate: editData?.startDate ?? "",
      endDate: editData?.endDate ?? "",
      flatOff: editData
        ? editData?.flatOff
          ? editData?.flatOff
          : editData?.flatOffPer
        : "",
      flatOffType: editData ? (editData?.flatOff ? "$" : "%") : "$",
      code: editData?.code ?? "",
      golfs: editData?.golfs ?? "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!selectedGolfs?.length) {
        return;
      }
      let bodyParams = {
        _id: editData ? editData?._id : undefined,
        code: values?.code ?? "",
        startDate: values?.startDate ?? "",
        endDate: values?.endDate ?? "",
        flatOff: values?.flatOffType === "$" ? values?.flatOff : 0,
        flatOffPer: values?.flatOffType === "%" ? values?.flatOff : 0,
        golfs: selectedGolfs?.length
          ? selectedGolfs?.map((golf: ISelectedGolf) => golf?.value)
          : [],
      };
      let response = await addingEditingCoupon(bodyParams);
      if (response?.status) {
        snackActions.success(
          response?.message ?? "Added / Updated Successfully!",
        );
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  const renderValueTypeFormat = (fieldName: string) => {
    return (
      <div style={{ width: 100, marginRight: 15 }}>
        <select
          className="form-select form-select-solid form-select-md"
          {...formik.getFieldProps(fieldName)}
        >
          <option value="$">$</option>
          <option value="%">%</option>
        </select>
      </div>
    );
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-750px scroll"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>Add / Edit Coupon</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>
            <Row className="pt-4">
              <Col xs={6}>
                <label className="form-label fw-bold">Code</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Code"
                  aria-label="Code"
                  {...formik.getFieldProps("code")}
                />
                {formik.touched.code && formik.errors.code && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.code}</div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">
                  Flat Off (Amount or Percentage)
                </label>
                <div className="d-flex align-items-center">
                  {renderValueTypeFormat("flatOffType")}
                  <AmountInput
                    placeholder="Flat Off"
                    formikProps={{ ...formik.getFieldProps("flatOff") }}
                    handleChange={(e) => formik.handleChange(e)}
                    isPercentage={formik.values.flatOffType === "%"}
                  />
                </div>
                {formik.touched.flatOff && formik.errors.flatOff && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.flatOff}</div>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="pt-6">
              <Col xs={6}>
                <label className="form-label fw-bold">Start Date</label>
                <div>
                  {/* <label
                    htmlFor="start-date"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                  >
                    mm-dd-yyyy
                  </label> */}
                  <input
                    id="start-date"
                    type="date"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="mm-dd-yyyy"
                    aria-label="Start Date"
                    {...formik.getFieldProps("startDate")}
                    title="mm-dd-yy"
                    // style={{
                    //   visibility: "hidden",
                    // }}
                  />
                  {formik.touched.startDate && formik.errors.startDate && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.startDate}
                      </div>
                    </div>
                  )}
                </div>
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">End Date</label>
                <input
                  type="date"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="End Date"
                  aria-label="End Date"
                  {...formik.getFieldProps("endDate")}
                  min={formik.values.startDate}
                />
                {formik.touched.endDate && formik.errors.endDate && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.endDate}</div>
                  </div>
                )}
              </Col>
            </Row>

            <Row className="pt-6">
              <Col xs={6}>
                <label className="form-label fw-bold">Select Golf</label>
                <MultiSelect
                  options={
                    golfData && !isGettingGolfData
                      ? golfData?.data?.docs?.map((d: any) => {
                          return { label: d?.name, value: d?._id };
                        })
                      : []
                  }
                  value={selectedGolfs}
                  onChange={setSelectedGolf}
                  labelledBy="Select"
                  className="form-control form-control-lg form-control-solid p-1"
                />
                {formik.touched.golfs && !selectedGolfs?.length && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      Golf selection is required
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
          <div className="w-100 d-flex p-5 align-items-center">
            <Button
              onClick={() => {
                formik.resetForm();
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              {isAddingEditing ? "Loading..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot,
  );
};

export default AddEditCoupon;
