import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "Squeez",
};

const dataSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setData(state, action) {
      state.title = action.payload;
    },
  },
});


export const { setData } = dataSlice.actions;


export default dataSlice.reducer;
