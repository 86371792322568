import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { KTIcon } from "../../_metronic/helpers";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import { ISubscriptionDetails } from "../../constants/types";
import { charValidate } from "../utility";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  details: ISubscriptionDetails;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const SubscriptionInfoDetails = ({ show, handleClose, details }: Props) => {
  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px scroll"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>Subscription Details</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-3">
        <Card style={{ width: "100%", boxShadow: "none" }}>
          <Card.Body>
            <Card.Title className="pb-4">User Details</Card.Title>
            <Container>
              <Row>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">
                    Full Name:
                  </Card.Subtitle>
                  <CustomTooltip
                    title={
                      <h6 style={{ color: "white" }}>
                        {details?.userId?.firstName +
                          " " +
                          details?.userId?.lastName}
                      </h6>
                    }
                  >
                    <Card.Text>
                      {details?.userId?.firstName || details?.userId?.lastName
                        ? charValidate(
                            details?.userId?.firstName +
                              " " +
                              details?.userId?.lastName,
                            20,
                          )
                        : "N/A"}
                    </Card.Text>
                  </CustomTooltip>
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">Email:</Card.Subtitle>
                  <CustomTooltip
                    title={
                      <h6 style={{ color: "white" }}>
                        {details?.userId?.email}
                      </h6>
                    }
                  >
                    <Card.Text>
                      {details?.userId?.email
                        ? charValidate(details?.userId?.email, 30)
                        : "N/A"}
                    </Card.Text>
                  </CustomTooltip>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>

        <div className="w-100 d-flex p-5 align-items-center">
          <Button
            onClick={() => {
              handleClose(false);
            }}
            size="lg"
            variant="secondary"
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>,
    modalsRoot,
  );
};

export default SubscriptionInfoDetails;
