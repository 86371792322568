import { useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import AmountInput from "../../_metronic/layout/components/common/AmountInput";
// import { updateSquezzMeStatus } from "../../api";
import { ISquezzMeInfoDetails } from "../../constants/types";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import Form from 'react-bootstrap/Form';
import { updateRequestStatusForAll, } from "../../api";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  details: ISquezzMeInfoDetails;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const ApproveSquezzMe = ({ show, handleClose, details }: Props) => {
  const { mutateAsync: updateRequestStatusForAllAPI } = useMutation(
    "update-status-all",
    updateRequestStatusForAll,
  );
  

  const validationSchema = Yup.object().shape({
    price: Yup.number()
      .required("Price is required and should not be blank")
      .min(1, "Price should be greater than zero"),
  });

  const formik = useFormik({
    initialValues: {
      price: "",
      description: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      let bodyParams : any = {
        categoryId:details?.catId,
        id: details?._id ? details?._id : undefined,
        status: 1,
        finalPrice : values.price,
        reasonText : values.description
      };
      let response: any = null;

      response = await updateRequestStatusForAllAPI(bodyParams);
      if (response?.status) {
        snackActions.success(response?.message ?? "Status Updated Successfully!");
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>Approve Request</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="pt-6">
            <label className="form-label fw-bold"> Price Per Person</label>
                <AmountInput
                  placeholder="Price"
                  formikProps={{ ...formik.getFieldProps("price") }}
                  handleChange={(e) => formik.handleChange(e)}
                />
                {formik.touched.price && formik.errors.price && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.price}
                    </div>
                  </div>
                )}
          </div>
          <div className="pt-6">
            <label className="form-label fw-bold">Description</label>
                 <Form>
                    <Form.Group className="mb-3">
                        <Form.Control as="textarea" rows={3} 
                        {...formik.getFieldProps("description")}
                        onChange={(e) => formik.handleChange(e)} />
                    </Form.Group>
                </Form>
                {formik.touched.description && formik.errors.description && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.description}
                    </div>
                  </div>
                )}
          </div>
          <div className="w-100 d-flex p-5 align-items-center">
            <Button
              onClick={() => {
                formik.resetForm();
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
             Submit
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot,
  );
};

export default ApproveSquezzMe;
