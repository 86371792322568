const charValidate = (text: string, limit: number) => {
  if (text && typeof text !== "undefined") {
    if (text.length > limit) {
      return `${text.substring(0, limit - 3)}...`;
    } else {
      return `${text.substring(0, limit)}`;
    }
  } else {
    return "";
  }
};

function convertTo24HourFormat(time12h : any) {
  const [time, period] = time12h.split(" ");

  let [hours, minutes] = time.split(":");
  hours = parseInt(hours);

  // Convert hours to 24-hour format if PM
  if (period === "PM" && hours !== 12) {
      hours += 12;
  }

  // Convert 12:00 AM (midnight) to 00:00
  if (period === "AM" && hours === 12) {
      hours = 0;
  }

  // Format the hours and minutes into a string in the format "HH:MM"
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
}


export { charValidate, convertTo24HourFormat };
