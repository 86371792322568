import { FormControl, InputGroup } from "react-bootstrap";

const priceInput = {
  border: "none",
  backgroundColor: "#F9F9F9",
};

const AmountInput = ({
  handleChange,
  formikProps,
  placeholder = "",
  isPercentage = false,
}: {
  handleChange: (e: any) => void;
  formikProps: any;
  placeholder: string;
  isPercentage?: boolean;
}) => {
  return (
    <>
      <InputGroup>
        <InputGroup.Text style={priceInput}>
          {isPercentage ? "%" : "$"}
        </InputGroup.Text>
        <FormControl
          type="number"
          placeholder={placeholder}
          aria-label={placeholder}
          {...formikProps}
          style={priceInput}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (!inputValue || /^\d+(\.\d{0,2})?$/.test(inputValue)) {
              handleChange(e);
            }
          }}
        />
      </InputGroup>
    </>
  );
};

export default AmountInput;
