import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { MultiSelect } from "react-multi-select-component";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useMutation, useQuery } from "react-query";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import {
  addAdmin,
  editAdmin,
  getAllGolfClub,
  getAllHotelList,
  getAllWellnessList,
  getCategories,
  getCategoryRestaurant,
} from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  title?: string | undefined;
  isForEditProfile?: boolean;
  editData: {
    _id?: string | undefined;
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: any;
    countryCode: any;
    password: string;
    // logo: string;
    // bio: string;
    // dateOfBirth: string;
    countryId: number;
    stateId: number;
    cityId: number;
    zip: number;
    manageGolfs: string[];
    manageRestaurants: string[];
    manageWellness: string[];
    manageHotels: string[];
    role: string[];
  };
};

interface ISelectedGolf {
  label: string;
  value: string;
}

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditAdmin = ({
  show,
  handleClose,
  editData,
  title,
  isForEditProfile = false,
}: Props) => {
  const [img, setImg] = useState();
  const [selectedGolfs, setSelectedGolf] = useState<ISelectedGolf[] | []>([]);
  const [selectedWellnessValue, setSelectedWellnessValue] = useState<
    any[] | []
  >([]);
  const [selectedHotelValue, setSelectedHotelValue] = useState<
    any[] | []
  >([]);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState("");
  const [selectedRestaurantValue, setSelectedRestaurantValue] = useState<
    ISelectedGolf[] | []
  >([]);

  const {
    mutateAsync: getRestaurantData,
    data: restaurantData,
    isLoading: isLoadingRestaurant,
    error: restaurantAPIError,
  } = useMutation("restaurant-list", getCategoryRestaurant);

  const {
    mutateAsync: getGolfClubs,
    data: golfData,
    isLoading: isGettingGolfData,
  } = useMutation("golf-list", getAllGolfClub);

  const { data: wellnessDropdownData } = useQuery(
    "getWellness-list",
    getAllWellnessList
  );

  const { data: hotelnessDropdownData } = useQuery(
    "getHotel-list",
    getAllHotelList
  );


  useEffect(() => {
    getCategories();
  }, []);

  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
  });

  useEffect(() => {
    getRestaurant({ offset: 0, limit: 1000, search: "" });
  }, []);

  const getRestaurant = async (
    query:
      | {
        offset: number | string;
        limit: number | string;
        search: string;
      }
      | undefined
  ) => {
    let response = await getRestaurantData(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  useEffect(() => {
    getGolfClubs({ limit: 1000 });
  }, []);

  useEffect(() => {
    if (editData && golfData) {
      let golfList = golfData?.data?.docs;
      if (golfList?.length) {
        let selectedGolfList: any = [];
        golfList?.forEach((golf: any) => {
          if (editData?.manageGolfs?.includes(golf?._id)) {
            selectedGolfList?.push({ label: golf?.name, value: golf?._id });
          }
        });
        setSelectedGolf(selectedGolfList);
      }
      // setSelectedGolf(editData?.manageGolfs ?? [])
    }
  }, [editData, golfData]);

  useEffect(() => {
    if (editData && restaurantData) {
      let restaurantList = restaurantData?.data?.docs;
      if (restaurantList?.length) {
        let selectedRestaurantList: any = [];
        restaurantList?.forEach((restaurant: any) => {
          if (editData?.manageRestaurants?.includes(restaurant?._id)) {
            selectedRestaurantList?.push({
              label: restaurant?.name,
              value: restaurant?._id,
            });
          }
        });
        setSelectedRestaurantValue(selectedRestaurantList);
      }
    }
  }, [editData, restaurantData]);

  useEffect(() => {
    if (editData && wellnessDropdownData) {
      let wellnessList = wellnessDropdownData?.data?.docs;
      if (wellnessList?.length) {
        let selectedWellnessList: any = [];
        wellnessList?.forEach((wellness: any) => {
          if (editData?.manageWellness?.includes(wellness?._id)) {
            selectedWellnessList?.push({
              label: wellness?.name,
              value: wellness?._id,
            });
          }
        });
        setSelectedWellnessValue(selectedWellnessList);
      }
    }
  }, [editData, restaurantData]);


  useEffect(() => {
    if (editData && hotelnessDropdownData) {
      let hotelList = hotelnessDropdownData?.data?.docs;
      if (hotelList?.length) {
        let selectedHotelList: any = [];

        hotelList?.forEach((wellness: any) => {
          if (editData?.manageHotels?.includes(wellness?._id)) {
            selectedHotelList?.push({
              label: wellness?.name,
              value: wellness?._id,
            });
          }
        });
        setSelectedHotelValue(selectedHotelList);
      }
    }
  }, [editData, restaurantData]);


  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    mobileNumber: Yup.string()
      .required("Mobile number is required")
      .min(10, "Mobile number format is incorrect"),
  });

  const formik: any = useFormik({
    initialValues: {
      firstName: editData?.firstName ?? "",
      lastName: editData?.lastName ?? "",
      email: editData?.email ?? "",
      mobileNumber: editData?.mobileNumber ?? "",
      countryCode: editData?.countryCode ?? "",
    },
    validationSchema: '',
    onSubmit: async (values) => {
      let bodyParams = {
        userId: editData ? editData?._id : undefined,
        firstName: values?.firstName ?? "",
        lastName: values?.lastName ?? "",
        mobileNumber: values?.mobileNumber
          ? values?.mobileNumber?.slice(`${values?.countryCode?.length}`)
          : "",
        countryCode:
          values?.countryCode != null && values?.countryCode !== ""
            ? !values?.countryCode.includes("+")
              ? `+${values?.countryCode}`
              : values?.countryCode
            : "1",
        email: values?.email ?? "",
        role: ["admin"],
        manageGolfs: isForEditProfile
          ? editData.manageGolfs
          : selectedGolfs?.length
            ? selectedGolfs?.map((golf: ISelectedGolf) => golf?.value)
            : [],
        manageRestaurants: isForEditProfile
          ? editData.manageRestaurants
          : selectedRestaurantValue?.length
            ? selectedRestaurantValue?.map(
              (restaurant: ISelectedGolf) => restaurant?.value
            )
            : [],
        manageWellness:
          isForEditProfile ? editData.manageWellness : selectedWellnessValue?.length ?
            selectedWellnessValue?.map((restaurant: any) => restaurant?.value)
            : [],

        manageHotels: isForEditProfile
          ? editData.manageHotels
          : selectedHotelValue?.length
            ? selectedHotelValue?.map(
              (restaurant: any) => restaurant?.value
            )
            : [],

      };

      if (editData) {
        let response = await editAPI(bodyParams);
        if (response?.status) {
          snackActions.success(
            response?.message ?? "Added/Edited Successfully!"
          );
          formik.resetForm();
          if (isForEditProfile) {
            localStorage.setItem("userInfo", JSON.stringify(response?.data));
          }
          handleClose(true);
        } else {
          snackActions.error(response?.message ?? "Something went wrong!");
        }
      } else {
        let response = await addAPI(bodyParams);
        if (response?.status) {
          snackActions.success(
            response?.message ?? "Added/Edited Successfully!"
          );
          formik.resetForm();
          handleClose(true);
        } else {
          snackActions.error(response?.message ?? "Something went  wrong!");
        }
      }
    },
  });

  const { mutateAsync: addAPI, isLoading: isAdding } = useMutation(
    "add-edit",
    addAdmin
  );
  const { mutateAsync: editAPI, isLoading: isEditing } = useMutation(
    "add-edit",
    editAdmin
  );

  useEffect(() => {
    if (editData) {
      formik.setFieldValue(
        "mobileNumber",
        editData?.countryCode + editData?.mobileNumber
      );
    }
  }, [editData]);

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>{editData?._id ? "Edit" : "Add"} Admin</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>
            <Row>
              <Col xs={6}>
                <label className="form-label fw-bold">First Name</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="First Name"
                  aria-label="First Name"
                  {...formik.getFieldProps("firstName")}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.firstName}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Last Name</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Last Name"
                  aria-label="Last Name"
                  {...formik.getFieldProps("lastName")}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.lastName}
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row className="pt-6">
              <Col xs={6}>
                <label className="form-label fw-bold">Email</label>
                <input
                  placeholder="Email"
                  aria-label="Email"
                  {...formik.getFieldProps("email")}
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  type="email"
                  name="email"
                  autoComplete="off"
                  disabled={editData ? true : false}
                  style={{
                    cursor: editData ? "not-allowed" : "initial",
                  }}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Mobile Number</label>
                <PhoneInput
                  country={"us"}
                  // variant="outlined"
                  onBlur={formik.handleBlur}
                  value={formik.values.mobileNumber}
                  onChange={(value: string, country: any) => {
                    formik.setFieldValue(
                      "countryCode",
                      country?.dialCode || ""
                    );
                    formik.setFieldValue("mobileNumber", value || "");
                  }}
                  inputStyle={{
                    background: "#f9f9f9",
                    border: "none",
                  }}
                  inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  // disableDropdown={true}
                  // disableCountryCode={true}
                  // onlyCountries={["us"]}
                  disabled={editData ? true : false}
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.mobileNumber}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="pt-6">
              <Col xs={6}>
                <label className="form-label fw-bold">Assign Restaurants</label>
                <MultiSelect
                  options={
                    restaurantData && !isLoadingRestaurant
                      ? restaurantData?.data?.docs?.map((d: any) => {
                        return { label: d?.name, value: d?._id };
                      })
                      : []
                  }
                  value={selectedRestaurantValue}
                  onChange={setSelectedRestaurantValue}
                  labelledBy="Select"
                  className="form-control form-control-lg form-control-solid p-1"
                />
                {/* {!isForEditProfile &&
                  formik.touched.manageRestaurants &&
                  !selectedRestaurantValue?.length && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        Restaurant selection is required
                      </div>
                    </div>
                  )} */}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Assign Golfs</label>
                <MultiSelect
                  options={
                    golfData && !isGettingGolfData
                      ? golfData?.data?.docs?.map((d: any) => {
                        return { label: d?.name, value: d?._id };
                      })
                      : []
                  }
                  value={selectedGolfs}
                  onChange={setSelectedGolf}
                  labelledBy="Select"
                  className="form-control form-control-lg form-control-solid p-1"
                />
                {/* {!isForEditProfile &&
                  formik.touched.manageGolfs &&
                  !selectedGolfs?.length && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        Golf selection is required
                      </div>
                    </div>
                  )} */}
              </Col>


            </Row>
            <Row className="pt-6">
              <Col xs={6}>
                <label className="form-label fw-bold">Assign Wellness</label>
                <MultiSelect
                  options={
                    wellnessDropdownData
                      ? wellnessDropdownData?.data?.docs?.map((d: any) => {
                        return { label: d?.name, value: d?._id };
                      })
                      : []
                  }
                  value={selectedWellnessValue}
                  onChange={setSelectedWellnessValue}
                  labelledBy="Select"
                  className="form-control form-control-lg form-control-solid p-1"
                />
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Assign Hotel</label>
                <MultiSelect
                  options={
                    hotelnessDropdownData
                      ? hotelnessDropdownData?.data?.docs?.map((d: any) => {
                        return { label: d?.name, value: d?._id };
                      })
                      : []
                  }
                  value={selectedHotelValue}
                  onChange={setSelectedHotelValue}
                  labelledBy="Select"
                  className="form-control form-control-lg form-control-solid p-1"
                />
                {/* {!isForEditProfile &&
                  formik.touched.manageGolfs &&
                  !selectedGolfs?.length && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        Golf selection is required
                      </div>
                    </div>
                  )} */}
              </Col>
            </Row>
          </Container>
          <div className="w-100 d-flex p-5 align-items-center mt-3">
            <Button
              onClick={() => {
                formik.resetForm();
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              {isAdding || isEditing ? "Loading..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  );
};

export default AddEditAdmin;
